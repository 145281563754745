const { conf } = require('./configService');

const showService = {
  getChannelName: channelMachineName => {
    const currentChannel = conf.tvSettings.natgeo_tv.channel_map?.find(
      element => element.machine_name === channelMachineName
    );
    return currentChannel?.channel_name || null;
  },

  getChannelMachineName: channelName => {
    const currentChannel = conf.tvSettings.natgeo_tv.channel_map?.find(
      element => element.channel_name === channelName
    );
    return currentChannel?.machine_name || null;
  },

  getRatingsIcon: rating => {
    if (rating) {
      return conf.tvSettings.natgeo_tv.ratings_icons[rating.toLowerCase()];
    }
    return null;
  }
};
module.exports = { showService };
