import loadable from '@loadable/component';
import { WithLazyHydration } from '../../../WithLazyHydration';

const ShowVideosLoadable = loadable(() => import('./ShowVideos'), {
  resolveComponent: components => components.ShowVideos
});

export const ShowVideos = WithLazyHydration(ShowVideosLoadable, {
  name: 'ShowVideos'
});
