import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { dateService } from '../domain/services/dateService';
import { apiClientService } from '../domain/services/apiClientService';
import { breakpointService } from '../domain/services/breakpointService';
import { imageResizerService } from '../domain/services/imageResizerService';
import { conf, t } from '../domain/services/configService';
import { showService } from '../domain/services/showService';
import { SmartLink } from './SmartLink';
import { theme } from '../domain/theme';

export const ShowCard = ({
  nid,
  imageEntity,
  header,
  showChannel,
  channel
}) => {
  const [upcomingEpisodes, setUpcomingEpisodes] = useState([]);

  let imagePath;
  if (imageEntity?.mediaImage?.url) {
    // TODO: Using smallest image for background until we refactor
    // this to be a <Image> component.
    // We need the Image Resizer to achieve that, because on mobile its aspect is 1:2
    // and there is no such image style.
    const currentBreakpoint =
      typeof window === 'object'
        ? breakpointService.getCurrentBreakpoint()
        : breakpointService.getSmallestBreakpoint();
    imagePath = imageResizerService.getStyleUrl(
      imageEntity.mediaImage.url,
      currentBreakpoint.width,
      null,
      { position: imageEntity.focalPoint }
    );
  }

  const styles = {
    container: css`
      position: relative;
      background-color: #000;
    `,
    imageContainer: css`
      aspect-ratio: 1 / 1;
      background-image: ${imagePath ? `url(${imagePath})` : null};
      background-size: cover;
      opacity: 0.6;
      background-position: center center;
      position: relative;
      color: #fff;
      background-color: #000;
      ${theme.mq.tablet} {
        aspect-ratio: 16 / 9;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 60%,
          rgba(0, 0, 0, 0.6) 100%
        );
        z-index: -1;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: clamp(120px, 22vw, 350px);
        height: clamp(80px, 19vw, 250px);
        background: rgba(0, 0, 0, 0.5);
      }
    `,
    textContainer: css`
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      color: #fff;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
    `,
    channel: css`
      ${theme.font.family('boldWeb')};
      font-size: clamp(
        ${theme.font.size('s')},
        2vw,
        ${theme.font.size('base')}
      );
      letter-spacing: ${theme.letterSpacing('l')};
      text-transform: uppercase;
      text-align: center;
      margin: ${theme.spacing.parse('$xs 0')};
    `,
    title: css`
      margin: 0 auto;
      padding: ${theme.spacing.parse('0 $base')};
      max-width: clamp(500px, 50vw, 900px);
      ${theme.font.family('regularWeb')};
      font-size: clamp(
        ${theme.font.size('xl')},
        3vw,
        ${theme.font.size('xl4')}
      );
      letter-spacing: ${theme.letterSpacing('l')};
      text-transform: uppercase;
      text-align: center;
    `,
    episodeContainer: css`
      background: #000;
      padding: ${theme.spacing.parse('0 $base $base $base')};
      color: #fff;
      ${theme.mq.small_desktop} {
        background: rgba(0, 0, 0, 0.5);
        max-width: 300px;
        position: absolute;
        top: 0;
        right: 0;
      }
    `,
    episodesTitle: css`
      padding: ${theme.spacing.parse('$xs 0 0 $xs')};
      text-transform: uppercase;
      ${theme.font.family('regularWeb')};
      font-size: ${theme.font.size('base')};
      letter-spacing: 2px;
      span {
        color: #fc0;
      }
      border-left: 1px solid #fc0;
    `,
    episode: css`
      ${theme.font.family('regularWeb')};
      margin-bottom: ${theme.spacing('base')};
      a {
        color: #fff;
        p {
          font-size: ${theme.font.size('xl')};
          ${theme.font.family('boldWeb')};
          line-height: 1.6rem;
          margin: ${theme.spacing.parse('$xl 0 $xs2 0')};
        }
        div {
          font-size: ${theme.font.size('s')};
          letter-spacing: 2px;
          text-transform: uppercase;
          color: #7c7c7c;
          span {
            color: #fff;
          }
        }
      }
    `,
    episodesFooter: css`
      text-transform: uppercase;
      ${theme.font.family('boldWeb')};
      font-size: ${theme.font.size('s')};
      letter-spacing: 2px;
      a {
        color: #fff;
      }
      span {
        color: #fc0;
        font-size: ${theme.font.size('xl')};
        font-weight: ${theme.font.weight('xl')};
        vertical-align: sub;
      }
    `
  };

  const getUpcomingEpisodes = async (limit = 3, channelMachineName) => {
    const { response } = await apiClientService.getJson(
      `/ajax/tv_schedule_grouped/${channelMachineName}.tv_schedule_grouped.json`
    );

    if (response && Array.isArray(response)) {
      const now = +new Date();
      // Show only programs that are on air now or will be broadcast in the future.
      const validEpisodes = response
        .filter(
          value =>
            Number(value.nid) === Number(nid) &&
            value.end_timestamp * 1000 > now
        )
        .slice(0, limit);
      setUpcomingEpisodes(validEpisodes);
    }
  };

  const channelMachineName =
    channel ||
    showService.getChannelMachineName(showChannel) ||
    conf.tvSettings.natgeo_tv.channel_map?.[0]?.machine_name;

  useEffect(() => {
    getUpcomingEpisodes(3, channelMachineName);
  }, []);

  return (
    <div>
      <div css={styles.container}>
        <div css={styles.imageContainer} />
        <div css={styles.textContainer}>
          <p css={styles.channel}>{showChannel}</p>
          <h1 css={styles.title}>{header}</h1>
        </div>
      </div>

      {upcomingEpisodes.length > 0 && (
        <div css={styles.episodeContainer}>
          <p css={styles.episodesTitle}>
            {t('Watch It On')}&nbsp;
            <span>{t('Television')}</span>
          </p>
          {upcomingEpisodes.map(episode => {
            return (
              <div
                css={styles.episode}
                key={`${episode.episodeTitle}-${episode.timestamp}`}
              >
                <SmartLink
                  title={episode.episodeTitle}
                  to={`${t(
                    '/television-schedule'
                  )}#schedule=${channelMachineName}/${dateService.format(
                    episode.timestamp * 1000,
                    'm/d/Y'
                  )}`}
                >
                  <p>{episode.episodeTitle}</p>
                  <div>
                    {episode.episode}&nbsp;&nbsp;
                    <span>
                      {dateService.format(
                        episode.timestamp * 1000,
                        'show_time'
                      )}
                    </span>
                  </div>
                </SmartLink>
              </div>
            );
          })}
          <p css={styles.episodesFooter}>
            <SmartLink
              className="white"
              css={styles.white}
              title={header}
              to={`${t(
                '/television-schedule'
              )}#schedule=${channelMachineName}/${dateService.format(
                upcomingEpisodes[0].timestamp * 1000,
                'm/d/Y'
              )}`}
            >
              {t('Watch This Program')}&nbsp;
              <span>+</span>
            </SmartLink>
          </p>
        </div>
      )}
    </div>
  );
};

ShowCard.propTypes = {
  nid: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  imageEntity: PropTypes.objectOf(PropTypes.any).isRequired,
  header: PropTypes.string.isRequired,
  showChannel: PropTypes.string.isRequired
};
