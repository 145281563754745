import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import WithLayout from '../WithLayout';
import { ContentPackage } from '../../components/Paragraph/ContentPackage/ContentPackage';
import { ShowVideos } from '../../components/responsive/organisms/ShowVideos';
import { showService } from '../../domain/services/showService';
import { stripTags } from '../../domain/utils/stringUtils';
import { mapContentToCardData } from '../../domain/utils/contentUtils';
import { ShowCard } from '../../components/ShowCard';
import { SponsoredBar } from '../../components/SponsoredBar';
import { RelatedContents } from '../../components/responsive/organisms/RelatedContents';
import { theme } from '../../domain/theme';

const nodeTypeShow = css`
  & .sponsor_additional_text {
    margin: 32px 60px;
    width: unset;
  }
`;

const showPageSynopsis = css`
  ${theme.font.family('regularWeb')};
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.1px;
  line-height: 1.5;
  color: #333;
  padding: 30px;
  position: relative;
  width: 100%;
  float: left;
  background-color: #000;
  color: #999;
  margin-bottom: 32px;
`;

const showPage = css`
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;
  padding: 0;

  & .fhero-pack {
    margin-bottom: 45px;
  }

  & .fhero-pack .title {
    border-left: none;
  }

  & .fhero-pack .title:before {
    content: '';
    width: 1px;
    display: block;
    position: absolute;
    background: #fc0;
    width: 3px;
    height: 27px;
    left: 0;
    top: 3px;
  }

  & .top-ad {
    display: inline-block;
    margin: 45px auto 10px;
  }

  & .tv-guide ul li {
    margin-top: 5px;
  }

  & .tv-guide ul li:last-child {
    display: none;
  }

  & .show-card {
    z-index: 50;
  }

  & .chan-card .card__header {
    top: 40%;
    transform: translate(-50%, -40%);
  }

  & .chan-card .card__header .card__cont--tit {
    display: none;
  }

  & .chan-card .card__header:before {
    height: 15px;
  }

  ${theme.mq.tablet} {
    & .tv-guide {
      margin-top: 60px;
    }

    & .tv-guide .tv-guide__container h2 {
      width: 52%;
      line-height: 2.75rem;
    }

    & .fhero-pack {
      margin-bottom: 70px;
    }

    & .top-ad {
      margin: 60px auto 20px;
    }
  }

  ${theme.mq.small_desktop} {
    & .tv-guide .tv-guide__container h2 {
      width: 65%;
    }

    & .fhero-pack {
      margin-bottom: 70px;
    }

    & .fhero-pack .title:before {
      height: 37px;
    }

    & .newsletter__info {
      margin-top: 20px;
    }

    & .search-results__ad-col {
      margin-top: 30px !important;
    }
  }

  ${theme.mq.large_desktop} {
    & .tv-guide .tv-guide__container h2 {
      width: auto;
    }
  }

  @media screen and (max-width: 767px) {
    & .show-card .card__image {
      padding-bottom: 100%;
    }
  }
`;

const Show = ({ content }) => {
  const articleTag = useRef(null);
  const ratingsIcon = showService.getRatingsIcon(content.rating);
  const showProps = mapContentToCardData(content);

  const channelData = {
    showChannel:
      content.setChannel && content.channelOverride
        ? content.channelOverride
        : showService.getChannelName(content.channel),
    channel: content.channel
  };

  return (
    <article
      data-node-id={content.id}
      // eslint-disable-next-line react/no-unknown-property
      about={content.url?.path}
      className="node node--type-show show-page"
      css={[nodeTypeShow, showPage]}
      ref={articleTag}
    >
      <SponsoredBar content={content} articleRef={articleTag} />

      <ShowCard
        nid={content.id}
        channel={channelData.channel}
        imageEntity={showProps.imageEntity}
        header={showProps.title}
        showChannel={channelData.showChannel}
      />
      <div css={showPageSynopsis}>
        {content.rating && content.rating !== 'None' && (
          <div>
            {ratingsIcon ? (
              <img
                src={ratingsIcon}
                alt={content.rating}
                width="20"
                height="20"
              />
            ) : (
              content.rating
            )}
          </div>
        )}
        {content.synopsis && content.synopsis.value && (
          <p>{stripTags(content.synopsis.value)}</p>
        )}
      </div>
      {content.mainContent && (
        <div className="node__cont" key={`node__cont_${content.id}`}>
          {content.mainContent.map((paragraph, index) => {
            const key = `${paragraph.entity.type}_${index}`;
            switch (paragraph.entity.type) {
              case 'ParagraphContentPackage':
                return (
                  <ContentPackage
                    data={paragraph.entity}
                    content={content}
                    isAboveTheFold={index === 0}
                    key={key}
                  />
                );
              default:
                break;
            }
            return null;
          })}
        </div>
      )}

      {content.episodeData && (
        <ShowVideos videos={content.episodeData} content={content} />
      )}
      {content.relatedContents && content.relatedContents.entities && (
        <RelatedContents
          contents={content.relatedContents.entities.map(entity => ({
            data: mapContentToCardData(entity)
          }))}
          content={content}
        />
      )}
    </article>
  );
};

Show.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired
};

export default WithLayout(Show);
