import loadable from '@loadable/component';
import { WithLazyHydration } from '../../../WithLazyHydration';

const RelatedContentsLoadable = loadable(() => import('./RelatedContents'), {
  resolveComponent: components => components.RelatedContents
});

export const RelatedContents = WithLazyHydration(RelatedContentsLoadable, {
  name: 'RelatedContents'
});
